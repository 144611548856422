import React from 'react';
import moment from 'moment';

export default {
  formatFromTarget: target => {
    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = Number(target.value);
        break;
      case 'react-datetime':
        value = moment(target.value).toISOString();
        break;
      default:
        value = target.value;
    }
    return value;
  },

  formatDateForAPI: date => {
    return moment(date).toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.sss');
  },

  formatDate: date => {
    return moment(date).format('DD/MM/YYYY');
  },

  formatDateShort: date => {
    return moment(date).format('DD/MM/YY');
  },

  formatDateTime: date => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  },

  formatWorkHours: workHoursInMinutes => {
    const workHours = Math.floor(workHoursInMinutes / 60);
    const workMinutes = workHoursInMinutes % 60;
    return '' + workHours + 'hs ' + workMinutes + 'm';
  },

  formatNumberDecimals: (number, decimals = 5) => {
    const decimalPlaces = 10 ** decimals;
    number = Math.round(number * decimalPlaces) / decimalPlaces;
    let parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  },

  getIssueType: labels => {
    let issueType = 'Feature';
    let existingIssueType;
    if (labels)
      existingIssueType = labels.find(
        label =>
          label.name == 'Feature' ||
          label.name == 'Error' ||
          label.name == 'Support',
      );
    if (existingIssueType) issueType = existingIssueType.name;
    return issueType;
  },

  getIssueEnvironment: labels => {
    let issueEnvironment = '';
    let existingIssueEnvironment;
    if (labels)
      existingIssueEnvironment = labels.find(
        label => label.name == 'Development' || label.name == 'Production',
      );
    if (existingIssueEnvironment)
      issueEnvironment = existingIssueEnvironment.name;
    return issueEnvironment;
  },

  getIssueUrgent: labels => {
    let issueUrgent = false;
    let existingIssueUrgent;
    if (labels)
      existingIssueUrgent = labels.find(label => label.name == 'Urgent');
    if (existingIssueUrgent) issueUrgent = true;
    return issueUrgent;
  },

  normalizeString: text => {
    text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return text;
  },
};
