import React from 'react';
import { useEffect, useState, useRef } from 'react';
import apiHelper from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import './IssueDetail.css';
import Loader from '../common/Loader';
import StickyHeader from './../common/StickyHeader';
import { BreadCrum } from '../common/BreadCrum';
import IssueComments from './IssueComments';
import SelectIssueType from '../common/selects/SelectIssueType';
import Attachments from '../common/Attachment';
import SelectIssueEnvironment from '../common/selects/SelectIssueEnvironment';
import SelectIssueUrgent from '../common/selects/SelectIssueUrgent';
import Tooltip from '../common/Tooltip';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import SelectIssueAssignDev from '../common/selects/SelectIssueAssignDev';
import { useAlert } from '../../context/AlertContext';
import ModalContent from '../common/ModalContent';
import SelectBranch from '../common/selects/SelectBranch';
import Editable from '../common/Editable';
import ReactPlayer from 'react-player/lazy';
import CommandBox from '../common/CommandBox';
const BRANCH_MODAL_STATE = {
  LOADING: 'LOADING',
  SELECT_BRANCH: 'SELECT_BRANCH',
  CREATED: 'CREATED',
  ERROR: 'ERROR',
  MERGE_BRANCHES: 'MERGE_BRANCHES',
};

const initState = {
  trigger: false,
  action: '',
};

const IssueDetail = props => {
  const [issue, setIssue] = useState({
    id: props.match.params.id,
    title: '',
    created_at: '',
    closed_at: '',
    issueType: '',
    environment: '',
    urgent: '',
    body: '',
    assignees: [],
  });
  const [attachments, setAttachments] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [isNew] = useState(props.match.params.id === 'new');
  const [isDisabled, setDisabled] = useState(true);
  const [openBranchModal, setOpenBranchModal] = useState(false);
  const [branchModalState, setBranchModalState] = useState(
    BRANCH_MODAL_STATE.LOADING,
  );
  const [branchName, setBranchName] = useState('');
  const hiddenFileInput = useRef(null);
  const alertToast = useAlert();
  const hiddenFileInputForBody = useRef(null);
  const [toDisplayFromBody, setToDisplayFromBody] = useState([]);
  const [destinyBranch, setDestinyBranch] = useState('');
  const [triggerForComment, setTriggerForComment] = useState(initState);
  const [onboarding, setOnboarding] = useState({
    stepsEnabled: apiHelper.getOnboarding('onboardingIssueDetail'),
    initialStep: 0,
    steps: [
      {
        element: '.firstStep',
        intro:
          '<h4>¡Bienvenido a la pantalla de detalle de tarea!</h4><br><p >Vamos a guiarle para crear una nueva tarea, o modificar una existente</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.secondStep',
        intro:
          '<p >En primer lugar, debe indicarse el título de la tarea. Debe ser breve y declarativo (Ej: "Error al intentar iniciar sesión") y deben evitarse títulos demasiado abstractos (Ej: "Error sistema") o demasiado extensos.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.thirdStep',
        intro:
          '<p >En segundo lugar, debe declararse el tipo de tarea: <b>Funcionalidad</b> (un agregado a la funcionalidad del proyecto, como por ejemplo una nueva pantalla), <b>Error</b> (una falla detectada sobre una funcionalidad ya definida) o <b>Soporte</b> (una tarea no vinculada a una funcionalidad del sistema, sino a facilitar su uso. Por ejemplo, la creación de nuevos usuarios).</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.fourthStep',
        intro:
          '<p >Finalmente, debe indicarse una descripción de la tarea. La misma debe ser lo más detallada posible, y en caso de necesidad, pueden incluirse adjuntos de todo tipo (imágenes, archivos de texto, archivos de Excel, etc) para facilitar la comprensión o implementación de la tarea.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.fifthStep',
        intro:
          '<p >Si la tarea fuera de tipo <b>Error</b>, se solicitará indicar el ambiente donde se produce la falla. Esto puede ser en <b>Desarrollo</b> (el entorno de pruebas del sistema) o <b>Producción</b> (el entorno real del sistema). En caso de que la falla sea en Producción, puede indicarse si el error es <b>Urgente</b> (impide el uso del sistema) o <b>No urgente</b> (puede continuar utilizándose).</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.sixthStep',
        intro:
          '<p >Una vez que ha completado toda la información necesaria, puede presionar en <b>Guardar</b> y verá que la tarea se agregará al proyecto actual.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.seventhStep',
        intro:
          '<p >Cuando se crea una nueva tarea en un proyecto, se notifica de forma <b>automática e inmediata</b> al equipo de desarrollo del mismo, lo que asegura un rápido tratamiento de la misma en caso de necesidad. Por esto, es un canal mucho más eficiente que otros medios de comunicación.</p>',
        tooltipClass: 'onboarding-steps',
      },
    ],
  });

  useEffect(() => {
    if (!isNew) {
      setLoading(true);
      apiHelper
        .getIssue(props.match.params.project, props.match.params.id)
        .then(response => {
          let issue = response.data;
          issue.issueType = formatter.getIssueType(issue.labels);
          issue.environment = formatter.getIssueEnvironment(issue.labels);
          issue.urgent = formatter.getIssueUrgent(issue.labels);
          setIssue(issue);
          setDisabled(response.data.disabled);
          extractMediaUrls(issue.body);

          setLoading(false);
        })
        .catch(error => {
          if (error?.response?.status == 404) {
            props.history.push('/project/' + props.match.params.project);
            alertToast.error(error.response.data.message);
          }
          console.log(error.response);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setDisabled(false);
    }
  }, []);

  const handleChange = event => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    value =
      target.type === 'react-datetime'
        ? formatter.formatDateForAPI(target.value)
        : target.value;
    const name = target.name;
    const newIssue = Object.assign({}, issue);
    newIssue[name] = value;
    // If the modified field is the issue type, and it has "Feature" value, clear "environment" and "urgent" fields
    if (name == 'issueType' && value == 'Feature') {
      newIssue['environment'] = '';
      newIssue['urgent'] = '';
    }
    setIssue(newIssue);
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const extractFileNameFromUrl = text => {
    const items = text.split('/');
    if (items.length > 1) {
      const fileName = items[items.length - 1];
      return fileName;
    }
    return text;
  };

  function extractMediaUrls(text) {
    const videoRegex = /https?:\/\/[^\s]+\.(mp4|mkv)/g;
    const imageRegex = /!\[Image\]\((https?:\/\/[^\s)]+)\)/g;
    const fileRegex = /\[File\]\((https?:\/\/[^\s)]+)\)/g;
    const mediaUrls = [];
    let match;

    // Buscar videos
    while ((match = videoRegex.exec(text)) !== null) {
      mediaUrls.push({ type: 'video', url: match[0] });
    }

    // Buscar imágenes
    while ((match = imageRegex.exec(text)) !== null) {
      mediaUrls.push({ type: 'image', url: match[1] });
    }
    //Buscar archivos
    while ((match = fileRegex.exec(text)) !== null) {
      mediaUrls.push({ type: 'file', url: match[1] });
    }
    setToDisplayFromBody(mediaUrls);
    return mediaUrls;
  }

  const handleChangeIssueBody = e => {
    setIssue({
      ...issue,
      body: e.target.value,
    });
    extractMediaUrls(e.target.value);
  };

  const handlePickFileForBody = e => {
    hiddenFileInputForBody.current.click();
  };

  const onPasteBody = async e => {
    const dT = e.clipboardData || window.clipboardData;
    const file = dT.files[0];
    if (dT.files.length == 0) {
      return;
    }
    handleFileChangeForBody({ target: { files: [file] } });
  };
  const handleFileChangeForBody = async event => {
    let file = event.target.files[0];
    if (file) {
      try {
        setLoading(true);
        const response = await apiHelper.postAttachmentForIssue(file);
        const fileUrl = response.data.fileUrl;
        // const fileUrl =
        //   'https://gratisography.com/wp-content/uploads/2024/10/gratisography-cool-cat-800x525.jpg';
        let finalPath;
        switch (file.type) {
          case 'image/png':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'image/jpeg':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'image/jpg':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'image/webp':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'video/mp4':
            finalPath = fileUrl;
            // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
            break;
          // case 'video/avi':
          //   finalPath = fileUrl;
          //   // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
          //   break;
          case 'video/x-matroska':
            finalPath = fileUrl;
            // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
            break;
          // case 'video/quicktime':
          //   finalPath = fileUrl;
          //   // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
          //   break;
          default:
            // finalPath = `[File](https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/467ec44a-a1f1-4fbc-b389-a99428ea487e..csv)`;
            finalPath = `[File](${fileUrl})`;
            break;
        }
        setIssue({
          ...issue,
          body: `${finalPath}
${issue.body}`,
        });

        extractMediaUrls(`${finalPath}
${issue.body}`);
      } catch (error) {
        console.log(error);
      }
    } else {
      setErrorMessage('No se seleccionó ningún archivo');
    }
    setLoading(false);
  };

  const handleFileChange = event => {
    let file = event.target.files[0];
    if (file) {
      let newAttachments = [];
      newAttachments = newAttachments.concat(attachments);
      if (!attachments.find(elem => elem.name == file.name))
        newAttachments.push(file);
      setAttachments(newAttachments);
    } else {
      setErrorMessage('No se seleccionó ningún archivo');
    }
  };

  const handleRemove = attachment => {
    let newAttachments = [];
    newAttachments = attachments.filter(elem => elem.name != attachment.name);
    setAttachments(newAttachments);
  };

  const submitAttachment = async (id, attachment) => {
    if (!attachment) return;
    try {
      setLoading(true);
      await apiHelper.postAttachment(
        props.match.params.project,
        id,
        attachment,
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      const statusCode = error.response.status;
      let errorMessage;
      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.response.data.error
          ? error.response.data.error.message
          : error.response.data;
      }
      setErrorMessage(errorMessage);
    }
  };

  const handleSave = async event => {
    if (isDisabled) {
      setTriggerForComment({
        trigger: true,
      });
    } else {
      submitIssue(event);
    }
  };

  const handleReject = () => {
    setTriggerForComment({
      trigger: true,
      action: 'REJECT',
    });
  };
  const handleAprove = () => {
    setTriggerForComment({
      trigger: true,
      action: 'APROVE',
    });
  };

  const submitIssue = async event => {
    event.preventDefault();
    try {
      setLoading(true);

      let number;
      //If requests already exists, update it. If not, create it
      props.history.push('/project/' + props.match.params.project);
      if (isNew) {
        alertToast.info('Creando tarea');
        const response = await apiHelper.postIssue(
          props.match.params.project,
          issue,
        );
        number = response.data.number;
        alertToast.success('Tarea creada');
        alertToast.setProjectToRefresh(props.match.params.project);
      } else {
        alertToast.info('Actualizando tarea');
        let patchedIssue = issue;
        patchedIssue.assignees = patchedIssue.assignees.map(i => i.login);
        const response = await apiHelper.patchIssue(
          props.match.params.project,
          props.match.params.id,
          patchedIssue,
        );
        number = response.data.number;
        alertToast.success('Tarea actualizada');
        alertToast.setProjectToRefresh(props.match.params.project);
      }
      await Promise.all(
        attachments.map(async attachment => {
          await submitAttachment(number, attachment);
        }),
      );
      setLoading(false);
      // props.history.push('/project/' + props.match.params.project);
    } catch (error) {
      setLoading(false);
      const statusCode = error?.response?.status;
      let errorMessage;
      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error?.response?.data?.error
          ? error?.response?.data?.error?.message
          : error?.response?.data;
      }
      if (!errorMessage) {
        setErrorMessage(
          'No se pudo crear su tarea, intente nuevamente más tarde',
        );
      } else {
        setErrorMessage(errorMessage);
      }
      if (isNew) {
        alertToast.error('Ocurrió un error al crear la tarea');
      } else {
        alertToast.error('Ocurrió un error al actualizar la tarea');
      }
    }
  };

  let alert;
  let title;

  if (errorMessage) {
    alert = <div className="alert alert-warning">{errorMessage}</div>;
  }

  if (isNew) {
    title = 'Crear tarea';
  } else {
    title = '#' + issue.number + ' ' + issue.title;
  }

  const onExit = async () => {
    await apiHelper.updateOnboarding({
      onboarding: 'onboardingIssueDetail',
      state: false,
    });
    setOnboarding(prevState => {
      return { ...prevState, stepsEnabled: false };
    });
  };

  const handlePRBranches = async () => {
    try {
      setBranchModalState(BRANCH_MODAL_STATE.LOADING);
      const response = await apiHelper.createIssueBranchPullRequest(
        props.match.params.project,
        issue.number,
        { destinyBranch },
      );
      setIssue({
        ...issue,
        pullRequests: [...issue.pullRequests, response.data.createdPullRequest],
      });
      alertToast.success('Pull request creado');
    } catch (error) {
      console.log(error.response);
      alertToast.error(error.response.data.error);
    }
    setBranchModalState(BRANCH_MODAL_STATE.MERGE_BRANCHES);
  };

  const getExcludedDestinyBranch = () => {
    let items = issue.pullRequests.map(pr => pr.base.ref);
    items.push(issue.branch.name);
    return items;
  };
  const handleMergeBranch = async pullNumber => {
    setBranchModalState(BRANCH_MODAL_STATE.LOADING);
    try {
      const response = await apiHelper.mergePullRequest(
        props.match.params.project,
        pullNumber,
      );
      let updatedPullRequests = [...issue.pullRequests];
      let targetPr = updatedPullRequests.find(pr => pr.number == pullNumber);
      targetPr.state = 'closed';
      setIssue({
        ...issue,
        pullRequests: updatedPullRequests,
      });
      alertToast.success('Pull request mergeado');
    } catch (error) {
      alertToast.error('Ocurrio un error al mergear el Pull Request');
    }
    setBranchModalState(BRANCH_MODAL_STATE.MERGE_BRANCHES);
  };

  const branchModalContent = () => {
    switch (branchModalState) {
      case BRANCH_MODAL_STATE.LOADING:
        return (
          <div>
            <Loader></Loader>
          </div>
        );
      case BRANCH_MODAL_STATE.SELECT_BRANCH:
        return (
          <div
            style={{
              minHeight: '200px',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <span className="text-white">Seleccionar rama de origen</span>

            <SelectBranch
              name={'branch'}
              disabled={false}
              required={true}
              value={branchName}
              onChange={handleChangeBranchInput}
              projectName={props.match.params.project}></SelectBranch>

            <div className="row" style={{ marginTop: '100px' }}>
              <div className="col-md-6">
                <button
                  type="button"
                  className="btn butt btn-success w-100"
                  onClick={craeteNewBranch}>
                  Crear rama
                  {/* <i className="fas fa-play ml-1" /> */}
                </button>
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  className="btn butt btn-success w-100"
                  onClick={() => setOpenBranchModal(false)}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        );
      case BRANCH_MODAL_STATE.CREATED:
        return (
          <div>
            <div>
              <span className="text-white">{`Se ha creado con exito la rama: ${issue.branch.name}`}</span>
            </div>

            <span className="text-white">Para acceder a la rama</span>
            <CommandBox
              command={`git pull
git switch ${issue.branch.name}`}></CommandBox>
            <div className="row">
              <div className="col-md-6">
                <button
                  type="button"
                  className="btn butt btn-success w-100"
                  onClick={() => setOpenBranchModal(false)}>
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        );
      case BRANCH_MODAL_STATE.ERROR:
        return <div></div>;
      case BRANCH_MODAL_STATE.MERGE_BRANCHES:
        return (
          <div
            style={{
              minHeight: '200px',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <span className="text-white">Para acceder a la rama</span>
            <CommandBox
              command={`git pull
git switch ${issue.branch.name}`}></CommandBox>
            <span className="text-white">
              Seleccionar rama destino del Pull Request
            </span>

            <SelectBranch
              name={'branch'}
              disabled={false}
              required={true}
              value={destinyBranch}
              exclude={getExcludedDestinyBranch()}
              onChange={handleDestinyBranchInput}
              projectName={props.match.params.project}></SelectBranch>
            <span className="text-white">Pull Requests ya generados:</span>
            <ul className="">
              {issue.pullRequests.map(pr => {
                return (
                  <li>
                    <div className="d-flex flex-row justify-content-between   align-items-center">
                      <p className="mt-3">{pr.base.ref}</p>{' '}
                      {pr.base.ref == 'master' && (
                        <a
                          target="_blank"
                          href={`https://github.com/venturing/${props.match.params.project}/pull/${pr.number}`}>
                          <button className="btn butt btn-info mr-3">
                            <i className="fas fa-external-link-alt mr-1" />
                            Ver en github
                          </button>
                        </a>
                      )}
                      {(pr.base.ref == 'dev' || pr.base.ref == 'qa') &&
                        pr.state == 'open' && (
                          <button
                            className="btn butt btn-info mr-3"
                            onClick={() => handleMergeBranch(pr.number)}>
                            <i className="fas fa-code-branch mr-1" />
                            Mergear ramas
                          </button>
                        )}
                      {pr.state == 'closed' && (
                        <button
                          disabled
                          className="btn butt btn-info mr-3"
                          onClick={() => handleMergeBranch(pr.number)}>
                          <i className="fas fa-check mr-1" />
                          Rama mergeada
                        </button>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>

            <div className="row" style={{ marginTop: '100px' }}>
              <div className="col-md-6">
                <button
                  type="button"
                  className="btn butt btn-success w-100"
                  onClick={handlePRBranches}>
                  Armar Pull Request
                  {/* <i className="fas fa-play ml-1" /> */}
                </button>
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  className="btn butt btn-success w-100"
                  onClick={() => setOpenBranchModal(false)}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        );
    }
  };
  const onOpenBranchModal = () => {
    if (issue.branch) {
      setBranchModalState(BRANCH_MODAL_STATE.MERGE_BRANCHES);
    } else {
      setBranchModalState(BRANCH_MODAL_STATE.SELECT_BRANCH);
    }
    setOpenBranchModal(true);
  };
  const handleChangeBranchInput = event => {
    const target = event.target;
    const value = target.value;
    setBranchName(value);
  };

  const handleDestinyBranchInput = event => {
    const target = event.target;
    const value = target.value;
    setDestinyBranch(value);
  };

  const craeteNewBranch = async () => {
    setBranchModalState(BRANCH_MODAL_STATE.LOADING);

    // console.log("NORMALIZED: ",formatter.normalizeString(issue.title))
    const response = await apiHelper.postProjectBranch(
      props.match.params.project,
      {
        origin: branchName,
        newBranch: `${issue.number}_${formatter
          .normalizeString(issue.title)
          .replaceAll(' ', '_')}`,
      },
    );
    setIssue({
      ...issue,
      branch: response.data.branch,
    });
    setBranchModalState(BRANCH_MODAL_STATE.CREATED);
  };

  return (
    <div className="d-flex flex-column h-100 general">
      <Steps
        enabled={onboarding.stepsEnabled && !loading}
        steps={onboarding.steps}
        initialStep={onboarding.initialStep}
        options={{
          nextLabel: 'Siguiente',
          prevLabel: 'Anterior',
          skipLabel: 'Saltar',
          doneLabel: 'Finalizar',
        }}
        onExit={onExit.bind(this)}
      />
      <StickyHeader titulo={title} icon={'fas fa-wrench icon-vtasks'} />
      <BreadCrum
        className="breadCrum"
        project={props.match.params.project}
        back={'Tareas'}
        actual={title}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="row mb-1 mt-3 justify-content-end">
            <div className="col-md-9">
              {!isNew && issue.milestone && (
                <h4 className="text-white">
                  {issue.milestone.title} - Fecha de entrega{' '}
                  {formatter.formatDate(issue.milestone.due_on)}
                </h4>
              )}
            </div>
            {!isNew && (
              <div className="d-flex flex-row">
                {!isNew && !apiHelper.isClient() && (
                  <button
                    className="btn butt btn-info mr-3"
                    onClick={onOpenBranchModal}>
                    <i className="fas fa-code-branch mr-1" />
                    {issue.branch ? 'Ver rama' : 'Crear rama'}
                  </button>
                )}
                {apiHelper.isAdmin() && (
                  <a
                    href={issue.html_url}
                    target="blank"
                    className="pull-right">
                    <button className="btn butt btn-info mr-3">
                      <i className="fas fa-external-link-alt mr-1" /> Ver en
                      GitHub
                    </button>
                  </a>
                )}
              </div>
            )}
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-md-12 col-lg-12 secondStep">
              <div className="form-group">
                <h4
                  className="inline-block text-white"
                  htmlFor="title"
                  data-tip
                  data-for={'Tooltip-title'}>
                  Título
                </h4>
                <Tooltip
                  id={'Tooltip-title'}
                  tooltipText={
                    'Síntesis de la tarea a realizar. Ej: "Error al exportar el reporte de usuarios"'
                  }
                />

                <input
                  type="text"
                  name="title"
                  maxLength="140"
                  className="form-control"
                  placeholder="No definido"
                  value={issue.title}
                  onChange={handleChange}
                  disabled={isDisabled}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-md-12 col-lg-12 thirdStep">
              <h4
                className="inline-block text-white"
                htmlFor="issueType"
                data-tip
                data-for={'Tooltip-issueType'}>
                Tipo de tarea
              </h4>
              <Tooltip
                id={'Tooltip-issueType'}
                tooltipText={
                  'Una tarea puede ser una nueva funcionalidad (o una modificación sobre una funcionalidad existente), un error, o una tarea de soporte'
                }
              />
              <SelectIssueType
                name="issueType"
                value={issue.issueType}
                placeholder="Seleccione un tipo de tarea"
                onChange={handleChange}
                required={true}
                disabled={isDisabled}
              />
            </div>
          </div>

          {!isNew && (
            <div className="row justify-content-center mt-3">
              <div className="col-md-12 col-lg-12 thirdStep">
                <h4
                  className="inline-block text-white"
                  htmlFor="issueType"
                  data-tip
                  data-for={'Tooltip-issueType'}>
                  Desarrollador asignado
                </h4>
                {/* <Tooltip
                id={'Tooltip-issueType'}
                tooltipText={
                  'Una tarea puede ser una nueva funcionalidad (o una modificación sobre una funcionalidad existente), un error, o una tarea de soporte'
                }
              /> */}
                <SelectIssueAssignDev
                  name="assignees"
                  value={issue.assignees.length > 0 ? issue.assignees : []}
                  placeholder="Seleccione el desarrollador para asignar la tarea"
                  onChange={handleChange}
                  options={issue.collaborators}
                  required={false}
                  disabled={apiHelper.isClient()}
                />
              </div>
            </div>
          )}

          <div className="row justify-content-center mt-4">
            {!isNew && (
              <div
                className={`col-sm-12 ${
                  issue.closed_at && 'col-lg-6 '
                }secondStep`}>
                <div className="form-group">
                  <h4
                    className="inline-block text-white"
                    htmlFor="created_at"
                    data-tip
                    data-for={'Tooltip-created_at'}>
                    Fecha de creación
                  </h4>
                  <Tooltip
                    id={'Tooltip-created_at'}
                    tooltipText={'Fecha de creación de la tarea'}
                  />
                  <input
                    type="text"
                    name="created_at"
                    maxLength="140"
                    className="form-control"
                    placeholder="No definido"
                    value={new Date(issue.created_at).toLocaleDateString()}
                    onChange={handleChange}
                    disabled
                    required
                  />
                </div>
              </div>
            )}
            {issue.closed_at && (
              <div className="col-sm-12 col-lg-6 secondStep">
                <div className="form-group">
                  <h4
                    className="inline-block text-white"
                    htmlFor="closed_at"
                    data-tip
                    data-for={'Tooltip-closed_at'}>
                    Fecha de cierre
                  </h4>
                  <Tooltip
                    id={'Tooltip-closed_at'}
                    tooltipText={'Fecha de cierre de la tarea'}
                  />
                  <input
                    type="text"
                    name="closed_at"
                    maxLength="140"
                    className="form-control"
                    placeholder="No definido"
                    value={new Date(issue.closed_at).toLocaleDateString()}
                    onChange={handleChange}
                    disabled
                    required
                  />
                </div>
              </div>
            )}
          </div>

          {(issue.issueType == 'Error' || issue.issueType == 'Support') && (
            <div className="row justify-content-center mt-3">
              {issue.environment != 'Production' ? (
                <div className="col-md-12 col-lg-12">
                  <h4
                    className="inline-block text-white"
                    htmlFor="environment"
                    data-tip
                    data-for={'Tooltip-environment'}>
                    Ambiente
                  </h4>
                  <Tooltip
                    id={'Tooltip-environment'}
                    tooltipText={
                      'Una tarea puede estar relacionada al ambiente de desarrollo (Ej: la definición de una nueva funcionalidad) o al ambiente de producción (Ej: un error al querer iniciar sesión en producción)'
                    }
                  />
                  <SelectIssueEnvironment
                    name="environment"
                    value={issue.environment}
                    placeholder="Seleccione el ambiente al que se asocia la tarea"
                    onChange={handleChange}
                    required={true}
                    disabled={isDisabled}
                  />
                </div>
              ) : (
                <>
                  <div className="col-md-6 col-lg-6 fifthStep">
                    <h4
                      className="inline-block text-white"
                      htmlFor="environment"
                      data-tip
                      data-for={'Tooltip-environment'}>
                      Ambiente
                    </h4>
                    <Tooltip
                      id={'Tooltip-environment'}
                      tooltipText={
                        'Una tarea puede estar relacionada al ambiente de desarrollo (Ej: la definición de una nueva funcionalidad) o al ambiente de producción (Ej: un error al querer iniciar sesión en producción)'
                      }
                    />
                    <SelectIssueEnvironment
                      name="environment"
                      value={issue.environment}
                      placeholder="Seleccione el ambiente al que se asocia la tarea"
                      onChange={handleChange}
                      required={true}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <h4
                      className="inline-block text-white"
                      htmlFor="urgent"
                      data-tip
                      data-for={'Tooltip-urgent'}>
                      ¿La tarea requiere tratamiento con urgencia?
                    </h4>
                    <Tooltip
                      id={'Tooltip-urgent'}
                      tooltipText={
                        'Una tarea es urgente si impide o afecta significativamente el uso del sistema. Sólo las tareas en el ambiente de producción pueden ser tratadas como urgentes'
                      }
                    />
                    <SelectIssueUrgent
                      name="urgent"
                      value={issue.urgent}
                      placeholder="Indique si la tarea requiere tratamiento con urgencia"
                      onChange={handleChange}
                      required={true}
                      disabled={isDisabled}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <div className="row justify-content-center mt-3">
            <div className="col-md-12 col-lg-12 fourthStep">
              <div className="form-group">
                <h4
                  className="inline-block text-white"
                  htmlFor="body"
                  data-tip
                  data-for={'Tooltip-description'}>
                  Descripción
                </h4>
                <Tooltip
                  id={'Tooltip-description'}
                  tooltipText={
                    'Explique la tarea con el mayor grado de detalle posible, incluyendo adjuntos, ejemplos y datos de prueba.'
                  }
                />
                {!isDisabled && (
                  <>
                    <div className="issue-body-enabled">
                      {toDisplayFromBody.length > 0 && (
                        <div className="d-flex justify-content-between flex-wrap pb-2">
                          {toDisplayFromBody.map((content, i) => {
                            if (content.type == 'image') {
                              return (
                                <img
                                  src={content.url}
                                  width={'100%'}
                                  style={{ borderRadius: '5px' }}
                                  className="mt-3 mb-1 mr-3 ml-3"
                                  key={`--${i}--${content.type}--${i}`}></img>
                              );
                            }
                            if (content.type == 'video') {
                              return (
                                <ReactPlayer
                                  url={content.url}
                                  controls={true}
                                  key={`--${i}--${content.type}--${i}`}
                                  width={'100%'}></ReactPlayer>
                              );
                            }
                            if (content.type == 'file') {
                              return (
                                <a
                                  target="_blank"
                                  href={content.url}
                                  className="mt-3 mb-1 mr-3 ml-3">
                                  <button className="btn butt btn-info mr-3">
                                    <i className="fas fa-external-link-alt mr-1" />
                                    {extractFileNameFromUrl(content.url)}
                                  </button>
                                </a>
                              );
                            }
                          })}
                        </div>
                      )}
                      <textarea
                        type="textarea"
                        name="body"
                        className="textarea-enabled"
                        placeholder="No definido"
                        rows="5"
                        value={issue.body}
                        onChange={handleChangeIssueBody}
                        disabled={isDisabled}
                        required
                        onPaste={onPasteBody}
                      />
                    </div>
                  </>
                )}
                {isDisabled && (
                  <div className="issue-body-disabled">
                    <div className="d-flex justify-content-between flex-wrap pb-2 mx-2">
                      {toDisplayFromBody.map((content, i) => {
                        if (content.type == 'image') {
                          return (
                            <img
                              src={content.url}
                              width={'100%'}
                              style={{ borderRadius: '5px' }}
                              className="mt-2 mb-2"
                              key={`--${i}--${content.type}--${i}`}></img>
                          );
                        }
                        if (content.type == 'video') {
                          return (
                            <ReactPlayer
                              url={content.url}
                              controls={true}
                              key={`--${i}--${content.type}--${i}`}
                              width={'100%'}></ReactPlayer>
                          );
                        }
                      })}
                    </div>
                    <p>{issue.body}</p>
                  </div>
                )}
                {/* <div className='custom-super-textarea form-control' contenteditable onInput={e => console.log('Text inside div', e.currentTarget.textContent)} onBlur={onContentBlur} dangerouslySetInnerHTML={{__html: input}}> 
                
                </div> */}
                {/* <Editable html={parseIssueBodyToHtml(issue.body)} onChange={handleChangeIssueBody}></Editable> */}

                {!isDisabled && (
                  <button
                    className="btn butt btn-info mr-3  mt-2"
                    onClick={handlePickFileForBody}
                    data-tip
                    data-for={'Tooltipimportissue'}>
                    <Tooltip
                      id="Tooltipimportissue"
                      tooltipText="Soporta todo tipo de archivos. Reconoce: Video: .mp4 | .mkv - Imagenes: .png | .jpeg | .jpg | .webp"></Tooltip>
                    Adjuntar archivo
                    <i className="fas fa-paperclip ml-2" />
                  </button>
                )}
                <input
                  id="file"
                  type="file"
                  style={{ display: 'none' }}
                  ref={hiddenFileInputForBody}
                  onChange={handleFileChangeForBody}
                  className="form-control-file"
                />
              </div>
            </div>
          </div>
          {!isNew && (
            <div className="text-center">
              <IssueComments
                project={props.match.params.project}
                id={props.match.params.id}
                disabled={isDisabled}
                forceLoading={() => setLoading(true)}
                breakLoading={() => setLoading(false)}
                trigger={triggerForComment}
              />
            </div>
          )}
          <Attachments attachments={attachments} onRemove={handleRemove} />
          {alert}
          <div className="row mb-1 mt-5 justify-content-center">
            {/*
            <div className="col-md-12 col-lg-6">
              <button className="btn butt w-100" onClick={handleClick}>
                <i className="fas fa-file-alt mr-2" />
                Adjuntar archivo
              </button>
              <input
                id="file"
                type="file"
                style={{ display: 'none' }}
                ref={hiddenFileInput}
                onChange={handleFileChange}
                className="form-control-file"
              />
            </div>
            */}
            {((apiHelper.isClient() && issue.column == 'Cliente') ||
              (apiHelper.isQA() && issue.column == 'QA')) && (
              <div className="col-md-12 col-lg-4">
                <button
                  className="btn butt w-100 sixthStep"
                  // onClick={submitIssue}
                  onClick={handleAprove}>
                  <i className="fas fa-check mr-2" />
                  Aprobar
                </button>
              </div>
            )}
            <div className="col-md-12 col-lg-4">
              <button
                className="btn butt w-100 sixthStep"
                // onClick={submitIssue}
                onClick={handleSave}>
                <i className="fas fa-save mr-2" />
                Guardar
              </button>
            </div>
            {((apiHelper.isClient() && issue.column == 'Cliente') ||
              (apiHelper.isQA() && issue.column == 'QA')) && (
              <div className="col-md-12 col-lg-4">
                <button
                  className="btn butt w-100 sixthStep"
                  // onClick={submitIssue}
                  onClick={handleReject}>
                  <i className="fas fa-times mr-2" />
                  Rechazar
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <ModalContent
        openModal={openBranchModal}
        title={issue.branch ? `Rama: ${issue.branch.name}` : 'Crear rama'}
        content={branchModalContent()}></ModalContent>
    </div>
  );
};

export default IssueDetail;
