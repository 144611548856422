import React, { useEffect, useRef, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import formatter from '../../utils/formatter';
import Tooltip from '../common/Tooltip';
import ReactLoading from 'react-loading';
import './IssueDetail.css';
import Loader from '../common/Loader';
import StickyHeader from './../common/StickyHeader';
import { useHistory } from 'react-router';
import { useAlert } from '../../context/AlertContext';
import ReactPlayer from 'react-player';

const IssueComments = props => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const hiddenFileInput = useRef(null);
  const hiddenFileInputForComment = useRef(null);
  const alert = useAlert();
  const [toDisplayFromComment, setToDisplayFromComment] = useState([]);

  const handlePickFileForComment = e => {
    hiddenFileInputForComment.current.click();
  };

  function extractMediaUrls(text) {
    const videoRegex = /https?:\/\/[^\s]+\.(mp4|mkv)/g;
    const imageRegex = /!\[Image\]\((https?:\/\/[^\s)]+)\)/g;
    const fileRegex = /\[File\]\((https?:\/\/[^\s)]+)\)/g;
    const mediaUrls = [];
    let match;

    // Buscar videos
    while ((match = videoRegex.exec(text)) !== null) {
      mediaUrls.push({ type: 'video', url: match[0] });
    }

    // Buscar imágenes
    while ((match = imageRegex.exec(text)) !== null) {
      mediaUrls.push({ type: 'image', url: match[1] });
    }
    //Buscar archivos
    while ((match = fileRegex.exec(text)) !== null) {
      mediaUrls.push({ type: 'file', url: match[1] });
    }
    setToDisplayFromComment(mediaUrls);
    return mediaUrls;
  }

  const handleChangeNewComment = e => {
    setNewComment(e.target.value);
    extractMediaUrls(e.target.value);
  };

  const findImagesLinks = text => {
    const regex = /!\[Image\]\((https?:\/\/[^\s)]+)\)/g;
    const urls = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      urls.push(match[1]);
    }

    return urls;
  };
  const findVideoUrls = text => {
    const regex = /https?:\/\/[^\s]+\.(mp4|mkv)/g;
    const urls = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      urls.push(match[0]);
    }

    return urls;
  };
  const findFilesUrls = text => {
    const regex = /\[File\]\((https?:\/\/[^\s)]+)\)/g;
    const urls = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      urls.push(match[1]);
    }

    return urls;
  };

  const onPasteBody = async e => {
    const dT = e.clipboardData || window.clipboardData;
    const file = dT.files[0];
    if (dT.files.length == 0) {
      return;
    }
    handleFileChangeForComment({ target: { files: [file] } });
  };

  const handleFileChangeForComment = async event => {
    let file = event.target.files[0];
    if (file) {
      try {
        setLoading(true);
        const response = await apiHelper.postAttachmentForIssue(file);
        const fileUrl = response.data.fileUrl;
        // const fileUrl="https://gratisography.com/wp-content/uploads/2024/10/gratisography-cool-cat-800x525.jpg"
        let finalPath;
        switch (file.type) {
          case 'image/png':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'image/jpeg':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'image/jpg':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'image/webp':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'video/mp4':
            finalPath = fileUrl;
            // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
            break;
          // case 'video/avi':
          //   finalPath = fileUrl;
          //   // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
          //   break;
          case 'video/x-matroska':
            finalPath = fileUrl;
            // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
            break;
          // case 'video/quicktime':
          //   finalPath = fileUrl;
          //   // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
          //   break;
          default:
            finalPath = `[File](${fileUrl})`;
            break;
        }

        setNewComment(`${finalPath}
${newComment}`);
        extractMediaUrls(`${finalPath}
${newComment}`);
      } catch (error) {
        console.log(error);
      }
    } else {
      setErrorMessage('No se seleccionó ningún archivo');
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getComments();
  }, []);

  useEffect(() => {
    if (props.trigger.trigger == true) {
      submitComment();
    }
  }, [props.trigger]);

  const getComments = async () => {
    try {
      let response = await apiHelper.getComments(props.project, props.id);
      setComments(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const extractFileNameFromUrl = text => {
    const items = text.split('/');
    if (items.length > 1) {
      const fileName = items[items.length - 1];
      return fileName;
    }
    return text;
  };

  const submitComment = async event => {
    try {
      setLoading(true);
      alert.info('Cargando cambios');
      history.push('/project/' + props.project);
      let reqBody = {
        body: newComment,
        action: props.trigger.action,
      };
      await apiHelper.postComment(props.project, props.id, reqBody);
      setLoading(false);
      alert.success('Cambios guardados');
      alert.setProjectToRefresh(props.project);
    } catch (error) {
      setLoading(false);
      const statusCode = error.response.status;
      let errorMessage;
      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.response.data.error
          ? error.response.data.error.message
          : error.response.data;
      }
      setErrorMessage(errorMessage);
      alert.error('Ocurrió un error al cargar los cambios');
    }
  };

  return (
    <div>
      <h4 className="inline-block text-white">Comentarios</h4>
      {comments.map(comment => {
        if (
          comment.body.toLowerCase().includes('.jpeg') ||
          comment.body.toLowerCase().includes('.jpg') ||
          comment.body.toLowerCase().includes('.png') ||
          comment.body.toLowerCase().includes('.webp')
        ) {
          // let urls = comment.body.match(/\bhttps?:\/\/\S+/gi);
          let urls = findImagesLinks(comment.body);
          let videosUrls = findVideoUrls(comment.body);
          let fileUrls = findFilesUrls(comment.body);
          let githubUrls = comment.body.match(
            /(?<=!\[image\]\()\bhttps?:\/\/\S+(?=\))/gi,
          );
          if (githubUrls)
            githubUrls.forEach(url => {
              comment.body = comment.body.replace('![image](' + url + ')', url);
            });
          return (
            <div key={comment.id} className="row justify-content-center mt-3">
              <div className="col-md-12 col-lg-12">
                <span className="text-white">
                  {comment.user.login} -{' '}
                  {formatter.formatDateTime(comment.created_at)}
                </span>
                <div className="issue-body-disabled">
                  <div className="">
                    {videosUrls.map((url, index) => (
                      <ReactPlayer
                        key={`-urll-video${index}-bideo`}
                        url={url}
                        controls={true}
                        width={'100%'}
                        className="mt-2 mb-2"
                      />
                    ))}
                  </div>
                  <div className="">
                    {urls.map((url, index) => (
                      <img
                        key={`-urll-img${index}-mages`}
                        src={url}
                        width={'100%'}
                        style={{ borderRadius: '5px' }}
                        className="mt-2 mb-2"
                        height={'auto'}
                      />
                    ))}
                  </div>
                  <div>
                    {fileUrls.map((url, index) => (
                      <a
                        key={`-urll-img${index}-mages--$[fileee]`}
                        target="_blank"
                        href={url}
                        className="mt-3 mb-1 mr-3 ml-3">
                        <button className="btn butt btn-info mr-3">
                          <i className="fas fa-external-link-alt mr-1" />
                          {extractFileNameFromUrl(url)}
                        </button>
                      </a>
                    ))}
                  </div>
                  <p>{comment.body}</p>
                </div>
                {/* <div className="">
                  {videosUrls.map((url, index) => (
                    <ReactPlayer
                      key={`-urll-video${index}-bideo`}
                      url={url}
                      controls={true}
                      width={'100%'}
                      className="mt-2 mb-2"
                    />
                  ))}
                </div>
                <div className="">
                  {urls.map((url, index) => (
                    <img
                      key={`-urll-img${index}-mages`}
                      src={url}
                      width={'100%'}
                      style={{ borderRadius: '5px' }}
                      className="mt-2 mb-2"
                      height={'auto'}
                    />
                  ))}
                </div>
                <textarea
                  type="textarea"
                  name="body"
                  className="form-control"
                  placeholder="No definido"
                  rows={comment.body.length / 80 + 1}
                  value={comment.body}
                  disabled={true}
                  required
                /> */}
              </div>
            </div>
          );
        } else {
          return (
            <div key={comment.id} className="row justify-content-center mt-3">
              <div className="col-md-12 col-lg-12">
                <span className="text-white">
                  {comment.user.login} -{' '}
                  {formatter.formatDateTime(comment.created_at)}
                </span>
                <textarea
                  type="textarea"
                  name="body"
                  className="form-control"
                  placeholder="No definido"
                  rows={comment.body.length / 80 + 1}
                  value={comment.body}
                  disabled={true}
                  required
                />
              </div>
            </div>
          );
        }
      })}
      {props.disabled && (
        <>
          <div className="row justify-content-center mt-5">
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <h4 className="inline-block text-white" htmlFor="body">
                  Nuevo comentario
                </h4>
                <div className="issue-body-enabled">
                  {toDisplayFromComment.length > 0 && (
                    <div className="d-flex justify-content-between flex-wrap pb-2">
                      {toDisplayFromComment.map((content, i) => {
                        if (content.type == 'image') {
                          return (
                            <img
                              src={content.url}
                              width={'100%'}
                              style={{ borderRadius: '5px' }}
                              key={`--${i}--${content.type}--${i}`}
                              className="mt-3 mb-1 mr-3 ml-3"></img>
                          );
                        }
                        if (content.type == 'video') {
                          return (
                            <ReactPlayer
                              className="mt-2 mb-2"
                              url={content.url}
                              controls={true}
                              width={'100%'}
                              key={`--${i}--${content.type}--${i}`}></ReactPlayer>
                          );
                        }
                        if (content.type == 'file') {
                          return (
                            <a
                              target="_blank"
                              href={content.url}
                              className="mt-3 mb-1 mr-3 ml-3">
                              <button className="btn butt btn-info mr-3">
                                <i className="fas fa-external-link-alt mr-1" />
                                {extractFileNameFromUrl(content.url)}
                              </button>
                            </a>
                          );
                        }
                      })}
                    </div>
                  )}
                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <textarea
                      type="textarea"
                      name="body"
                      className="textarea-enabled"
                      placeholder="No definido"
                      rows="5"
                      value={newComment}
                      onChange={handleChangeNewComment}
                      required
                      onPaste={onPasteBody}
                    />
                  )}
                </div>

                <div className="d-flex">
                  {props.disabled && (
                    <button
                      className="btn butt btn-info mr-3  mt-2 "
                      onClick={handlePickFileForComment}
                      data-tip
                      data-for={'TooltipimportissueComment'}>
                      Adjuntar archivo
                      <i className="fas fa-paperclip ml-2" />
                      <Tooltip
                        id="TooltipimportissueComment"
                        tooltipText="Soporta todo tipo de archivos. Reconoce: Video: .mp4 | .mkv - Imagenes: .png | .jpeg | .jpg | .webp"></Tooltip>
                    </button>
                  )}
                </div>

                <input
                  id="file"
                  type="file"
                  style={{ display: 'none' }}
                  ref={hiddenFileInputForComment}
                  onChange={handleFileChangeForComment}
                  className="form-control-file"
                />
              </div>
            </div>
          </div>
          {/* <div className="row mb-1 mt-1 justify-content-end">
            <div className="col-md-12 col-lg-12">
              <button className="btn butt btn-info" onClick={submitComment}>
                <i className="fas fa-paper-plane mr-2" />
                Enviar comentario
              </button>
            </div>
          </div> */}
        </>
      )}
      {errorMessage}
    </div>
  );
};

export default IssueComments;
